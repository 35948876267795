.error-layout {
  padding: 8px;
}

.error-layout h3 {
  margin-bottom: 24px;
}

.flex-wrapper p {
  display: flex;
  flex-direction: row;
}

.flex-wrapper p span {
  flex: 1 1 auto;
  margin-right: 8px;
  min-width: 24px;
}

.instructions .icon {
  background-color: var(--pink);
  height: 24px;
  width: 24px;
}

@media (min-width: 768px) {
  .error-layout {
    padding-top: 72px;
  }

  .content {
    margin-left: 82px;
  }

  .error-layout h3 {
    margin-bottom: 24px;
  }

  .flex-wrapper {
    display: flex;
  }

  .flex-wrapper p {
    flex: 1 1 auto;
    flex-direction: column;
  }

  .flex-wrapper p span {
    margin-bottom: 32px;
  }

  .instructions .icon {
    height: 64px;
    width: 64px;
  }
}
